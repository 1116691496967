import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import type { ICreateCourse } from "@/types/api/ICreateCourse";
import type { ISpeakerCreate } from "@/types/entities/ISpeakerCreate";
import type { IQuestionCreate } from "@/types/entities/IQuestionCreate";
import { ActivityContentTypeEnum } from "@/utils/enum/ActivityContentTypeEnum";
import { default as availableInLanguagesList } from "@/utils/constants/languages";
import { ICourseContentTypeLink } from "@/types/entities/ICourseContentTypeLink";

export const useCourseStore = defineStore(
  "course",
  () => {
    const defaultCourseOverview = {
      description: "",
      educational_objectives: [""],
      commercial_supporter: "",
    };
    const defaultCourse = {
      title: "",
      poster: null,
      credits: [],
      is_featured: false,
      released_at: "",
      expired_at: "",
      estimated_time: 0,
      languages: [],
      price: 0,
      course_provider: "",
      joint_provider: "",
      overview: { ...defaultCourseOverview },
      information: [{ title: "", description: "" }],
      instructions: [{ title: "", description: "" }],
      specialities_id: [],
      has_certificate: true,
      passing_score: 0,
      speakers: [],
      questions: [],
      audiences_id: [],
      content_type: ActivityContentTypeEnum.link,
      content_list: [],
      reminder_delay: 0,
      can_skip_activity: false,
      documents: [],
      resources: [],
    };
    const courseData = reactive<ICreateCourse>({ ...defaultCourse });

    const defaultSpeaker = {
      name: "",
      institution: "",
      position: "",
      location: "",
      information: "",
      disclosure: "",
      degree: "",
      avatar: null,
      speaker_functions: [],
    };
    const speakers = ref<ISpeakerCreate[]>([{ ...defaultSpeaker }]);

    const pretestQuestions = ref<IQuestionCreate[]>([]);
    const activityQuestions = ref<IQuestionCreate[]>([]);
    const posttestQuestions = ref<IQuestionCreate[]>([]);
    const evaluationQuestions = ref<IQuestionCreate[]>([]);
    const followUpQuestions = ref<IQuestionCreate[]>([]);

    const contentTypeLinks = ref<ICourseContentTypeLink[]>([
      {
        lang: {
          ...availableInLanguagesList[0],
        },
        link: "",
      },
    ]);

    const clearAll = (): void => {
      Object.assign(courseData, {
        ...defaultCourse,
        overview: { ...defaultCourseOverview },
      });
      speakers.value = [{ ...defaultSpeaker }];
      pretestQuestions.value = [];
      activityQuestions.value = [];
      posttestQuestions.value = [];
      evaluationQuestions.value = [];
      followUpQuestions.value = [];
      contentTypeLinks.value = [
        {
          lang: {
            ...availableInLanguagesList[0],
          },
          link: "",
        },
      ];
    };

    return {
      speakers,
      courseData,
      pretestQuestions,
      activityQuestions,
      posttestQuestions,
      evaluationQuestions,
      followUpQuestions,
      contentTypeLinks,
      clearAll,
    };
  },
  {
    persist: true,
  }
);
